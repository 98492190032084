import { render, staticRenderFns } from "./HeroSlider.vue?vue&type=template&id=5cdc8667&scoped=true&"
import script from "./HeroSlider.vue?vue&type=script&lang=js&"
export * from "./HeroSlider.vue?vue&type=script&lang=js&"
import style0 from "./HeroSlider.vue?vue&type=style&index=0&id=5cdc8667&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5cdc8667",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CartCountdownAltAuto: require('/opt/build/repo/components/Cart/CountdownAltAuto.vue').default,UIReviewsAlt: require('/opt/build/repo/components/UI/ReviewsAlt.vue').default})
